import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import useDeviceDetect from "../service/useDeviceDetect"
import CustomLink from '../components/addon/Link'

import { objectDeepClone, dateToYear, formatAwardDate } from "../service/helper"

import { FacebookShareButton, TwitterShareButton } from "react-share";

const Awards = props => {
    const { data } = props
    let metaTags;
    const [awards, setAwards] = useState([])
    const [visibleAwards, setVisibleAwards] = useState([])
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(false)
    const [initialVisibleCount, setInitialVisibleCount] = useState(4)
    const { isMobile } = useDeviceDetect()
    const [offsetDivId, setOffSetDivId] = useState("");

    const location = useLocation();
    metaTags = data?.allNodeAwards?.nodes[0]?.metatags ? data?.allNodeAwards?.nodes[0].metatags : []
    const schema = data?.allNodeAwards?.node?.relationships?.field_seo_schema ? data?.allNodeAwards?.node?.relationships?.field_seo_schema : []
    const defaultImageSrc =
        isMobile === true
            ? "https://via.placeholder.com/200x249"
            : "https://via.placeholder.com/270x337"

    useEffect(() => {
        if (
            data &&
            data.allNodeAwards &&
            data.allNodeAwards &&
            data.allNodeAwards.nodes
        ) {
            let components = data.allNodeAwards.nodes[0]?.relationships?.awards
                ? objectDeepClone(data.allNodeAwards.nodes[0].relationships.awards)
                : []
            components = components.sort((a, b) => {
                let date1 = new Date(a.date).getTime()
                let date2 = new Date(b.date).getTime()
                return date2 - date1
            })
            setAwards(components)
            const arr = components.slice(0, initialVisibleCount)
            setVisibleAwards(arr)
            
        }
    }, [])

    const [metaImage, setMetaImage] = useState("")

    const renderImage = data => {
        if (data) {
            const imageSrc =
                isMobile === true
                    ? data?.relationships?.award_mobile?.uri?.url
                    : data?.relationships?.award?.uri?.url
            const altText = data?.award?.alt || "Hinduja Hospital"
            return imageSrc ? (
                <img src={imageSrc} alt={altText} />
            ) : (
                <img src={defaultImageSrc} alt={altText} />
            )
        } else {
            return null
        }
    }

    const loadMoreData = () => {
        let id = (visibleAwards.length + 2).toString()        
        setInitialVisibleCount(awards.length)
        let arr = [...awards]
        arr = arr.sort((a, b) => {
            const date1 = new Date(a.date).getTime()
            const date2 = new Date(b.date).getTime()
            return date2 - date1
        })
        setVisibleAwards(arr)
        setShowLoadMoreButton(false)
        setOffSetDivId(id);
    }

    useEffect(() => {
        if (offsetDivId.length) {
            let award = document.getElementById(offsetDivId)
            if(award){
                window.scroll({ top: award.offsetTop, behavior: "smooth" })
            }
        }

    }, [offsetDivId])

    const pageInfo = data?.allNodeAwards?.nodes?.length ? data?.allNodeAwards?.nodes[0] : null;

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: ["/assets/css/awards.css"],
                }}
                tags={metaTags}
                imgSrc={metaImage}
            />
            <Schema schema={schema} />
            {pageInfo?.relationships?.field_breadcrumb_awards &&
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                    {pageInfo?.relationships?.field_breadcrumb_awards?.field_breadcrumb_link?.map((el, index) => {
                                                return  (
                                                <li key={index}>
                                                    <CustomLink data={{link : el}}>{el.title}</CustomLink>
                                                </li>
                                            )
                                        })
                                    }
                                    <li><span>{pageInfo?.relationships?.field_breadcrumb_awards?.field_title}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <main className="innerpage">
                 <section className="awards_banner">
                    <div className="container">
                        <h2 className="section-heading text-left">{pageInfo?.title || 'Hinduja Hospital Awards'}</h2>
                    </div>
                </section>

                <section className="awards_section section-py">
                    <div className="container">
                        <span className="vertical_line"></span>
                        {visibleAwards &&
                            visibleAwards.length > 0 &&
                            visibleAwards.map((el, index) => {
                                return (
                                    <div key={index} className="row" id={index + 1}>
                                        <div className="col-md-4">
                                            <figure className="award_img">{renderImage(el)}</figure>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="award_details">
                                                <div className="date">
                                                    {el.date && (
                                                        <>
                                                            <span className="year">

                                                                {dateToYear(el.date)}{" "}
                                                            </span>
                                                            <span className="fulldate">
                                                                {formatAwardDate(el.date)}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                                <h2 className="title">{el.name ? el.name : ""}</h2>
                                                <figure className="award_img_mob">
                                                    {renderImage(el)}
                                                </figure>
                                                <p className="desc">{el.blurb ? el.blurb : ""}</p>
                                                <ul className="social_list">
                                                    <li>
                                                        <TwitterShareButton quote={el.name} url={location.href}>
                                                            <a
                                                                href="javascript:void(0)"
                                                                aria-label="follow us on twitter"
                                                                onClick={() => setMetaImage(el?.relationships?.award?.uri?.url)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="25.408"
                                                                    height="20.657"
                                                                    viewBox="0 0 25.408 20.657"
                                                                >
                                                                    <path
                                                                        data-name="Icon zocial-twitter"
                                                                        d="M-2.369,18.143q.62.06,1.239.061a10.247,10.247,0,0,0,6.486-2.23,5.023,5.023,0,0,1-3.036-1.034A5.08,5.08,0,0,1,.482,12.358a4.912,4.912,0,0,0,.971.083,5.106,5.106,0,0,0,1.384-.186A5.026,5.026,0,0,1-.169,10.469,5.051,5.051,0,0,1-1.357,7.153V7.09a4.89,4.89,0,0,0,2.375.64,5.117,5.117,0,0,1-1.7-1.849,5.082,5.082,0,0,1-.63-2.488A5.063,5.063,0,0,1-.592.77,14.493,14.493,0,0,0,4.149,4.623a14.673,14.673,0,0,0,6,1.6A5.861,5.861,0,0,1,10,5.026a5.013,5.013,0,0,1,1.529-3.677,5.038,5.038,0,0,1,3.7-1.529,5.008,5.008,0,0,1,3.8,1.653A10.488,10.488,0,0,0,22.337.193a4.968,4.968,0,0,1-2.272,2.892,10.643,10.643,0,0,0,2.975-.826,10,10,0,0,1-2.583,2.706v.683a14.948,14.948,0,0,1-.609,4.183,14.884,14.884,0,0,1-1.859,4.008A15.959,15.959,0,0,1,15,17.235,13.231,13.231,0,0,1,10.841,19.6a15.212,15.212,0,0,1-5.216.877A14.635,14.635,0,0,1-2.369,18.143Z"
                                                                        transform="translate(2.369 0.18)"
                                                                        fill="#024594"
                                                                    ></path>
                                                                </svg>
                                                            </a>
                                                        </TwitterShareButton>
                                                    </li>
                                                    <li>
                                                        <FacebookShareButton quote={el.name} url={location.href}>
                                                            <a
                                                                href="javascript:void(0)"
                                                                aria-label="join us on facebook"
                                                                onClick={() => setMetaImage(el?.relationships?.award?.uri?.url)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="12.704"
                                                                    height="23.613"
                                                                    viewBox="0 0 12.704 23.613"
                                                                >
                                                                    <path
                                                                        data-name="Icon zocial-facebook"
                                                                        d="M6.629,12.68V8.171h3.637V5.9a5.934,5.934,0,0,1,1.593-4.18A5.068,5.068,0,0,1,15.72,0h3.613V4.51H15.72a.8.8,0,0,0-.637.39,1.582,1.582,0,0,0-.283.956V8.17h4.533V12.68H14.8V23.613H10.265V12.68Z"
                                                                        transform="translate(-6.629)"
                                                                        fill="#024594"
                                                                    ></path>
                                                                </svg>
                                                            </a>
                                                        </FacebookShareButton>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {awards.length > initialVisibleCount && (
                        <div className="container no-after">
                            <div className="text-center">
                                <a
                                    onClick={loadMoreData}
                                    href="javascript:void(0)"
                                    className="btn btn-primary"
                                >
                                    Load more
                                </a>
                            </div>
                        </div>
                    )}
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
query awardsPage {

    allNodeAwards(

      filter: {path: {alias: {regex: "/awards$/"}}}

    ) {

      nodes {

        id

        metatags: metatag_normalized {

          attributes {

            content

            name
            property

          }

        }

        title

        relationships {

          awards: field_award {

            __typename

            ...Award

          }

          field_breadcrumb_awards {

            field_title

            field_breadcrumb_link {

              title

              uri

            }

          }

        }

      }

    }

  }

 

  fragment Award on paragraph__award {

    id

    name: field_title

    blurb: field_blurb

    date: field_date

    award: field_image {

      alt

    }

    relationships {

      award: field_image {

        id

        uri {

            value

            url

        }

      }

    }

  }
`
export default Awards
